<template>
    <centered-layout>
        <div class="text-center">
            <img
                :src="imageSrc"
                width="200px"
                alt="Sorry"
            >
        </div>
        <h5
            class="fw-light"
            v-html="title"
        />

        <p
            v-show="subTitle"
            v-html="subTitle"
            class="text-muted"
        />
        <!-- we use anchor tag and button tag for accessibility reasons -->
        <!-- http://web-accessibility.carnegiemuseums.org/content/buttons/ -->
        <a
            class="btn btn-secondary mt-3"
            :href="anchorHref"
            v-html="cta"
            v-show="showAnchor"
        />
        <div class="d-grid">
            <button
                class="btn btn-secondary mt-3"
                @click="showWebWidget"
                @keydown.enter="showWebWidget"
                v-html="cta"
                v-show="showButton"
            />
        </div>
        <p
            class="text-center text-muted mt-3"
            v-html="footer"
            v-show="!!footer"
        />
    </centered-layout>
</template>

<script>
    import CenteredLayout from '@/layouts/Centered'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import zendeskMixin from '@/mixins/zendeskMixin'
    import { SchedulingThanksReasons, MiscThanksReasons } from '@/utils/thanksPageHelpers'
    import { originationPagePaths } from '@/routes/originationRoutes'

    const reasonsToImgMap = new Map([
        [MiscThanksReasons.geo, require('@/assets/images/global/sorryidentification.svg')],
        [MiscThanksReasons.identity, require('@/assets/images/global/sorryidentification.svg')],
        [MiscThanksReasons.noCameraAccess, require('@/assets/images/notary/cameraNotWorking.svg')],
        [MiscThanksReasons.authorization, require('@/assets/images/global/padlockshield.svg')],
        ['priorCompletedAppointment', require('@/assets/images/origination/Reschedule.svg')],
    ])

    const reasonsToHrefMap = new Map([['priorCompletedAppointment', originationPagePaths.NOTARY_SCHEDULING]])

    const hideAnchorReasons = [
        MiscThanksReasons.review,
        MiscThanksReasons.default,
        MiscThanksReasons.notaryRejection,
        MiscThanksReasons.lien,
        MiscThanksReasons.privateBrowsing,
        MiscThanksReasons.authorization,
    ]

    const showButtonReasons = [MiscThanksReasons.review]

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        mixins: [zendeskMixin],
        data: function () {
            const queryReason = this.$route.query.reason || MiscThanksReasons.default
            return {
                queryReason,
                imageSrc: reasonsToImgMap.get(queryReason) || require('@/assets/images/global/sorryicecream.svg'),
                anchorHref: reasonsToHrefMap.get(queryReason) || process.env.VUE_APP_ORIGINATION_BASE_URL + '/support',
                showAnchor: !hideAnchorReasons.includes(queryReason),
                showButton: showButtonReasons.includes(queryReason),
                title: i18n.t(`pages.shared.thanks.reasons.${queryReason}.title`),
                subTitle: '', // computed in mounted
                cta: i18n.te(`pages.shared.thanks.reasons.${queryReason}.cta`) ? i18n.t(`pages.shared.thanks.reasons.${queryReason}.cta`) : i18n.t(`pages.shared.thanks.reasons.default.cta`),
                footer: '', // computed in mounted
            }
        },
        mounted: function () {
            this.setPageContent()

            logger.info(`showed sorry page (Reason: ${this.queryReason})`)
            this.$logEvent('view_thanks', { reason: this.queryReason })

            logger.log('Page redirects to non-public page, not clearing storage')
        },
        methods: {
            setPageContent: function () {
                const phoneNumber = appSessionStorage.getItem(localStorageKey.phoneNumber) || '?'
                const applicationNumber = appSessionStorage.getItem(localStorageKey.loanApplicationId) || '?'
                const mailErrorSubject = `Regarding the Application Process (Phone #: ${phoneNumber} | Application #: ${applicationNumber})`

                //toString required here for editor highlighting to work correctly
                switch (this.queryReason.toString()) {
                    case MiscThanksReasons.default:
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            link: appSessionStorage.getItem(localStorageKey.startPagePath) || process.env.VUE_APP_ORIGINATION_BASE_URL,
                            displayEmail: contact_info.support_email,
                            displayPhone: contact_info.phone,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            telTo: `tel:${contact_info.phone}`,
                        })
                        break
                    case SchedulingThanksReasons.ineligibleAppointment:
                        this.anchorHref = appSessionStorage.getItem(localStorageKey.startPagePath) || process.env.VUE_APP_ORIGINATION_BASE_URL
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`)
                        break
                    case MiscThanksReasons.review:
                        this.footer = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.footer`, {
                            displayEmail: contact_info.support_email,
                            displayPhone: contact_info.phone,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            telTo: `tel:${contact_info.phone}`,
                        })
                        break
                    case MiscThanksReasons.authorization:
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.authorization.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displayPhone: contact_info.sms,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            smsTo: `sms:${contact_info.sms}`,
                        })
                        break
                    case MiscThanksReasons.notaryRejection:
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displayPhone: contact_info.phone,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            telTo: `tel:${contact_info.phone}`,
                        })
                        break
                    case MiscThanksReasons.noCameraAccess:
                        this.title = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.title`, {
                            applicantPhone: appSessionStorage.getItem(localStorageKey.phoneNumber) || 'the phone number you gave us',
                        })
                        break
                    case MiscThanksReasons.unexpectedErrorOnNotarizationPage:
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayPhone: contact_info.phone,
                            telTo: `tel:${contact_info.phone}`,
                        })
                        break
                    case MiscThanksReasons.privateBrowsing:
                        this.subTitle = i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`, {
                            displayEmail: contact_info.support_email,
                            displayPhone: contact_info.phone,
                            mailto: `mailto:${contact_info.support_email}?subject=${mailErrorSubject}`,
                            telTo: `tel:${contact_info.phone}`,
                        })
                        break
                    default:
                        this.subTitle = i18n.te(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`) ? i18n.t(`pages.shared.thanks.reasons.${this.queryReason}.subTitle`) : ''
                }
            },
        },
    }
</script>
