<template>
    <div class="container pt-6">
        <div
            v-if="loading"
            class="vh-100 px-1"
        >
            <loading-indicator show-loader />
        </div>
        <div
            v-show="!loading"
            class="d-flex flex-column col-12 col-md-6 text-center m-auto"
        >
            <div class="d-none d-md-block text-center pb-4">
                <img
                    src="@/assets/images/global/aven.svg"
                    class="logo"
                    width="71"
                    alt="Go Home"
                >
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    export default {
        name: 'Centered',
        components: { LoadingIndicator },
        props: {
            loading: { default: false, type: Boolean },
        },
    }
</script>
